import { useRef } from "react";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import styles from "./Hero.module.css";
import { FaEnvelope, FaGithub, FaLinkedin } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const Hero = () => {
	const navigate = useNavigate();
	const ref = useRef<HTMLDivElement>(null);
	const [, , hasEntered] = useIntersectionObserver(ref, {});

	return (
		<div
			ref={ref}
			className={`${styles.hero} d-flex flex-row 
      ${hasEntered ? `animate-blur-rise` : ``}
      }`}
			style={{ gap: "2rem" }}
		>
			<div className={styles.profile} onClick={() => navigate("/")}></div>
			<HeroDetails />
		</div>
	);
};

const HeroDetails = () => (
	<div className={`d-flex flex-column ${styles["details-container"]}`}>
		<h1 className={styles["hi-text"]}>
			Hi! I'm <span className="accent">Ricky</span>.
		</h1>
		<span className={styles.heroDetailsText}>
			<em>
				Entrepreneur, Engineer, and Consultant with a background in Software,
				IT, and Business.
			</em>
		</span>
		<br />
		<span className={styles.heroDetailsText}>
			Looking to chat? Let's connect.
		</span>
		<SocialButtons />
	</div>
);

const SocialButtons = () => {
	const navigateTo = (url: string) => window.open(url, "_blank");
	return (
		<div className="d-flex flex-row" style={{ gap: "1rem", padding: "12px 0" }}>
			<FaLinkedin
				className={styles["social-icon"]}
				onClick={() => navigateTo(`https://linkedin.com/in/rickyxiaoyang`)}
			/>
			<FaGithub
				className={styles["social-icon"]}
				onClick={() => navigateTo(`https://github.com/rickyxiaoyang`)}
			/>
			<FaEnvelope
				className={styles["social-icon"]}
				onClick={() => navigateTo(`mailto:rickyxiaoyang@gmail.com`)}
			/>
		</div>
	);
};
