import { AboutMe } from "../About/About";

export default function Home() {
	return (
		<div>
			{/* <Hero /> */}
			<AboutMe />
		</div>
	);
}
