import { useNavigate } from "react-router-dom";
import styles from "./About.module.css";
export function About() {
	return (
		<div>
			<EducationSection />
		</div>
	);
}

export function AboutMe() {
	return (
		<div className={`section animate-blur-rise ${styles[`about-me`]}`}>
			<h3>About Me</h3>
			<p>
				Hello! I'm Ricky. I'm a software engineer by day, and almost everything
				else by night (I'm kidding... kinda).
			</p>
			<p>
				I have a deep passion for learning and building new things. My interests
				span business, technology, real estate, photography, cooking, personal
				finance... the list goes on, and continues to grow each year.
			</p>
			<p>
				Currently, I'm working on a youth sports education product at{" "}
				<a href="https://imgacademy.com/plus" target="_blank" rel="noreferrer">
					IMG Academy
				</a>
				, running my dessert businesses (
				<a
					href="https://instagram.com/taiyakinyc"
					target="_blank"
					rel="noreferrer"
				>
					@taiyakinyc
				</a>{" "}
				&{" "}
				<a
					href="https://instagram.com/thedoughclub"
					target="_blank"
					rel="noreferrer"
				>
					@thedoughclub
				</a>
				) as usual, freelancing (consulting, software development), and a ton of
				side projects.
			</p>
			<p>Feel free to check out some of my work!</p>
			<LinkGroup />
		</div>
	);
}

const LinkGroup = () => {
	const navigate = useNavigate();
	return (
		<div
			className="d-flex flex-column"
			style={{ gap: "1rem", marginTop: "2rem" }}
		>
			<button onClick={() => navigate("work")} className="btn">
				Career: Technology / Business
			</button>
			<button onClick={() => navigate("photography")} className="btn">
				Photography
			</button>
			<button
				onClick={() => {
					window.open(`https://roadtoyummy.com`, "_blank", "noreferrer");
				}}
				className="btn"
			>
				Cooking Blog
			</button>
		</div>
	);
};

const EducationSection = () => {
	return <div className="section"></div>;
};
