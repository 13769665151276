import { useCallback, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery, { PhotoProps, RenderImageProps } from "react-photo-gallery";
import { photos } from "./photos";
import "./Gallery.css";

const PhotoGallery = () => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);

	const openLightbox = useCallback(
		(event: any, { photo, index }: { photo: PhotoProps; index: number }) => {
			setCurrentImage(index);
			setViewerIsOpen(true);
		},
		[]
	);

	const closeLightbox = () => {
		setCurrentImage(0);
		setViewerIsOpen(false);
	};

	const renderImage = ({ photo, index }: RenderImageProps) => {
		return (
			<div
				className="gallery-image"
				style={{
					background: `url('${photo.src}')`,
					backgroundSize: "cover",
				}}
				onClick={() => {
					setCurrentImage(index);
					setViewerIsOpen(true);
				}}
			></div>
		);
	};

	return (
		<div>
			<Gallery
				photos={photos}
				onClick={openLightbox}
				renderImage={renderImage}
			/>
			{/* @ts-ignore */}
			<ModalGateway>
				{viewerIsOpen ? (
					<Modal onClose={closeLightbox}>
						<div className="carousel">
							<Carousel
								currentIndex={currentImage}
								views={photos.map((x: any) => ({
									...x,
									srcset: x.srcSet,
								}))}
							/>
						</div>
					</Modal>
				) : null}
			</ModalGateway>
		</div>
	);
};

export default PhotoGallery;
