import { useRef } from "react";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import styles from "./WorkList.module.css";

const byStartDate = (a: { startDate: Date }, b: { startDate: Date }) =>
	b.startDate.getTime() - a.startDate.getTime();

export const WorkList = () => {
	const workHistory: WorkListItem[] = [
		{
			company: "IMG Academy",
			title: "Software Engineer",
			startDate: new Date("2021-11-08"),
			imageUrl: "images/img-academy-logo.png",
			description: `Building the IMG+ platform, a suite of products to enrich the youth athlete experience. Technologies: Web (React), iOS (Swift, SwiftUI), Node (Typescript)`,
		},

		{
			company: "ICF",
			title: "Sr. Software Engineer",
			startDate: new Date("2020-12-07"),
			endDate: new Date("2021-11-01"),
			imageUrl: "images/icf-logo.png",
			description: `Lead/architect on multiple consulting engagements involving custom application development (Angular 2+, Node) and ServiceNow implementations (ITSM, CSM)`,
		},
		{
			company: "Highmetric",
			title: "Technical Consultant",
			startDate: new Date("2019-01-21"),
			endDate: new Date("2020-12-04"),
			imageUrl: "images/highmetric-logo.jpeg",
			description: `First technical consulting role, primarily focused in the ServiceNow space. Quickly learned the business and handled increasingly large clients & projects, spanning many modules.`,
		},
		{
			company: "Con Edison",
			title: "Engineer",
			startDate: new Date("2012-06-09"),
			endDate: new Date("2019-01-21"),
			imageUrl: "images/coned-logo.png",
			description: ``,
		},
	];

	return (
		<div
			className="d-flex flex-column"
			style={{ gap: "2rem", marginTop: "3rem" }}
		>
			<hr style={{ width: "100%", maxWidth: "300px" }} />
			{workHistory.sort(byStartDate).map((item) => (
				<div key={`${item.company}-${item.startDate.getTime()}`}>
					<WorkItem item={item} />
					<hr />
				</div>
			))}
		</div>
	);
};

type WorkListItem = {
	company: string;
	title: string;
	imageUrl: string;
	startDate: Date;
	endDate?: Date;
	description: string;
};

const dateString = (date: Date) =>
	new Date(date).toLocaleDateString("en-us", {
		year: "numeric",
		month: "short",
	});

const WorkItem = ({ item }: { item: WorkListItem }) => {
	const ref = useRef<HTMLDivElement>(null);
	const [, , hasEntered] = useIntersectionObserver(ref, {});

	const { title, company, imageUrl, startDate, endDate, description } = item;

	const durationString = () => {
		const from = dateString(startDate);
		const to = endDate !== undefined ? dateString(endDate) : "Present";
		return `${from} - ${to}`;
	};
	return (
		<div
			ref={ref}
			className={`d-flex row-to-column ${styles["container"]} ${
				hasEntered ? `animate-blur-rise` : ``
			}`}
		>
			<div
				className={styles.image}
				style={{ backgroundImage: `url(${imageUrl})` }}
			></div>
			<div className={styles[`work-text`]}>
				<h3 className={styles["title-text"]}>{title}</h3>
				<div
					className="d-flex row-to-column"
					style={{ justifyContent: "space-between", alignItems: "center" }}
				>
					<em>{company}</em>
					<span className={styles.duration}>{durationString()}</span>
				</div>
				<div className={styles.description}>{description}</div>
			</div>
		</div>
	);
};
