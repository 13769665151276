import { ToggleThemeButton } from "../../theme/theme";
import "./Footer.css";

const yearString = (date: Date) =>
  date.toLocaleDateString("en-us", { year: "numeric" });
export default function Footer() {
  const year = yearString(new Date());
  return (
    <div className="footer">
      <ToggleThemeButton />
      <div>
        ©{year} <span>rickyxiaoyang.com</span>
      </div>
    </div>
  );
}
