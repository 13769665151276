import PhotoGallery from "./Gallery";

const PhotographyPage = () => {
	return (
		<div className="animate-blur-rise">
			<div className="section">
				<h3>Photography</h3>
				<p>
					Take a look at a snippet of my work. Photography has been a huge hobby
					of mine ever since High School, and it's one I continue to enjoy to
					this day.
				</p>
				<p>
					I've had the fortune of shooting for clients, friends, and family
					across many occasions. Mainly, I enjoy shooting portraits, wedding
					engagements, and product shots for restaurants.
				</p>
				<p>
					If you like them, let's find an opportunity to work together! I'll
					take any reason to prevent my camera from getting dusty.
				</p>
				<br />
			</div>
			<PhotoGallery />
		</div>
	);
};

export default PhotographyPage;
