import { useRef } from "react";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import styles from "./WorkSection.module.css";
import { WorkList } from "./WorkList";

export function WorkSection() {
	const taiyakiSection = (
		<PictureWithDescription
			imageSrc={"images/taiyaki-cones.jpg"}
			position="bottom"
			size="medium"
		>
			<div
				className="d-flex flex-column"
				style={{ gap: "1rem", maxWidth: "500px" }}
			>
				<div style={{ fontSize: "14px" }}>
					I'm a co-founder at{" "}
					<a
						href="https://instagram.com/taiyakinyc"
						target="_blank"
						rel="noreferrer"
					>
						Taiyaki NYC
					</a>{" "}
					. Our specialty is our fish-shaped waffle cone, swirled with
					soft-serve ice cream! We first opened our doors in 2016, and have
					since grown to many other locations.
				</div>
			</div>
		</PictureWithDescription>
	);

	const theDoughClubSection = (
		<div>
			<PictureWithDescription
				imageSrc="images/donuts.jpeg"
				position="top"
				size="medium"
			>
				<div style={{ fontSize: "14px", maxWidth: "500px" }}>
					In 2019, we opened a new concept:{" "}
					<a
						href="https://instagram.com/thedoughclub"
						target="_blank"
						rel="noreferrer"
					>
						The Dough Club
					</a>
					, specializing in pon-de-ring style mochi donuts.
				</div>
			</PictureWithDescription>
		</div>
	);

	const jobsSection = (
		<div className="section">
			<h2 className={styles.headline}>As a Software Engineer</h2>
			<span>
				I enjoy building impactful software. My experience spans various
				fields/industries: energy, consulting, IT, and most recently, education.
				Here are some companies I've worked for.
			</span>
			<WorkList />
		</div>
	);

	const covidSection = (
		<div>
			<div className="section">
				<h2 className={styles.headline}>As a community member</h2>
				<span>
					The pandemic period was a tough time for all. Businesses were hit hard
					due to lockdown. Xenophobia and hate crimes spread like wildfire.
					Being from NYC's Chinatown, I saw firsthand the impact that it had on
					my home. Here's what I did.
				</span>
			</div>
			<br />
			<PictureWithDescription
				position="top"
				size="medium"
				imageSrc="/images/pc-group.jpeg"
			>
				<div style={{ maxWidth: "500px" }}>
					<div style={{ fontSize: "18px", fontWeight: "600" }}>
						Protect Chinatown
					</div>
					<span style={{ fontSize: "13px" }}>
						To combat the rise in anti-Asian violence, I founded an organization
						focused on safety initiatives, such as escorting elders, performing
						neighborhood patrols, distributing safety equipment (personal
						alarms), administering food distributions, and spreading awareness.
					</span>
				</div>
			</PictureWithDescription>
			<PictureWithDescription
				position="top"
				size="medium"
				imageSrc="/images/grocery.jpg"
			>
				<div style={{ maxWidth: "500px" }}>
					<div style={{ fontSize: "18px", fontWeight: "600" }}>Konveny</div>
					<span style={{ fontSize: "13px" }}>
						Formed as a response to lockdown, Konveny supplied frozen food,
						snacks & groceries through contactless delivery. This was especially
						important, since many were afraid or unable to leave their home to
						shop.
					</span>

					<div style={{ fontSize: "10px", marginTop: ".5rem" }}>
						Photo by{" "}
						<a href="https://unsplash.com/@nicosmit99?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
							Nico Smit
						</a>{" "}
						on{" "}
						<a href="https://unsplash.com/photos/PTv-B97DHNI?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
							Unsplash
						</a>
					</div>
				</div>
			</PictureWithDescription>
		</div>
	);

	return (
		<div
			className="d-flex flex-column animate-blur-rise"
			style={{ gap: "5rem", marginTop: "5rem" }}
		>
			<div className="section">
				<span>
					Preface: I'm a big believer of <strong>working in teams</strong>. I've
					been fortunate enough to meet, learn from, and work with many
					extraordinary people.
				</span>

				<div>
					Through years of hard work, collaboration, and admittedly, failing
					over and over... Here's some of my work.
				</div>
			</div>
			<div>
				<h2 className={styles.headline}>As an Entrepreneur</h2>
				{taiyakiSection}
				{theDoughClubSection}
			</div>
			{jobsSection}
			{covidSection}
		</div>
	);
}

type PictureWithDescriptionProps = {
	imageSrc: string;
	position?: "bottom" | "center" | "top";
	children: JSX.Element;
	size?: "small" | "medium" | "large";
};

function PictureWithDescription({
	imageSrc,
	position,
	children,
	size = "medium",
}: PictureWithDescriptionProps) {
	const ref = useRef<HTMLDivElement>(null);
	const [, , hasEntered] = useIntersectionObserver(ref, {});

	const currentImage = `url(${imageSrc})`;

	return (
		<div
			ref={ref}
			className={`${styles.container} ${
				hasEntered ? `animate-blur-rise` : ``
			} ${styles[`container-align-${position}`]} ${styles[`picture-${size}`]}`}
		>
			<div
				className={styles["image"]}
				style={{ backgroundImage: currentImage }}
			></div>
			<div
				className={`${styles["text-container"]} ${
					hasEntered ? `animate-blur-rise-longer` : ``
				}`}
			>
				{children}
			</div>
		</div>
	);
}
