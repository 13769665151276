import { PhotoProps } from "react-photo-gallery";

export const photos: PhotoProps[] = [
	{
		src: "https://user-images.githubusercontent.com/32840398/221383705-807292a1-2603-4127-97aa-2b15832f9466.jpg",
		width: 3,
		height: 2,
	},
	{
		src: "https://user-images.githubusercontent.com/32840398/221383794-48e82b2c-a002-49e9-be79-12320583a767.jpg",
		width: 3,
		height: 2,
	},
	{
		src: "https://user-images.githubusercontent.com/32840398/221383791-4ea098d2-c545-49a8-9486-bda22fe1280d.jpg",
		width: 2,
		height: 3,
	},
	{
		src: "https://user-images.githubusercontent.com/32840398/221383792-082e873d-8ce2-4f0a-9871-47eafb097d2e.jpg",
		width: 2,
		height: 3,
	},
	{
		src: "https://user-images.githubusercontent.com/32840398/221383793-b9cf74c6-c4b6-492a-ae60-54e9218bed7e.jpg",
		width: 2,
		height: 3,
	},
	{
		src: "https://user-images.githubusercontent.com/32840398/221383708-7e0e9eb7-ef7f-4dd9-bfce-dcc978fe0c21.jpg",
		width: 2,
		height: 3,
	},
	{
		src: "https://user-images.githubusercontent.com/32840398/221383709-884acd10-bc02-4d51-a933-18627aca5ccc.jpg",
		width: 2,
		height: 3,
	},
	{
		src: "https://user-images.githubusercontent.com/32840398/221383712-5e06e750-173e-4589-91a4-ac3d39a9a635.jpg",
		width: 2,
		height: 3,
	},
	{
		src: "https://user-images.githubusercontent.com/32840398/221383713-19a48ce5-82e0-468f-9c54-694f1ee8d8e2.jpg",
		width: 3,
		height: 4.5,
	},
	{
		src: "https://user-images.githubusercontent.com/32840398/221383795-9e078d86-174a-406c-a217-f5c67cdca5f3.jpg",
		width: 5,
		height: 3,
	},
	{
		src: "https://user-images.githubusercontent.com/32840398/221383707-294110c1-b9cf-4a42-af1f-bd2fbe7c7161.jpg",
		width: 2,
		height: 3,
	},
];
