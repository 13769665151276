import "./App.css";
import { WorkSection } from "./pages/Work/WorkSection";
import { Toolbar, ToolbarItem } from "./components/Toolbar/Toolbar";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import { Hero } from "./components/Hero/Hero";
import { ThemeContext } from "./theme/theme";
import { useContext } from "react";
import PhotographyPage from "./pages/Photography/Photography";

function App() {
	const navigate = useNavigate();
	const { theme } = useContext(ThemeContext);
	const toolbarItems: ToolbarItem[] = [
		{
			label: "About",
			action: () => navigate("/"),
		},
		{ label: "Work", action: () => navigate("/work") },
		{ label: "Contact", action: () => {} },
	];

	const wrapped = (
		<>
			<Hero />
			<Outlet />
		</>
	);
	return (
		<div className="App" data-theme={theme} style={{ gap: "2rem" }}>
			{false && <Toolbar items={toolbarItems} />}
			<Routes>
				<Route path="" element={wrapped}>
					<Route path="" index element={<Home />}></Route>
					<Route path="work" element={<WorkSection />}></Route>
					<Route path="photography" element={<PhotographyPage />}></Route>
				</Route>
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
